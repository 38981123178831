

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: "react-chat-app-7528e.firebaseapp.com",
    projectId: "react-chat-app-7528e",
    storageBucket: "react-chat-app-7528e.appspot.com",
    messagingSenderId: "435711274555",
    appId: "1:435711274555:web:5c21ac82c7b24a1bf9761e"
};


export default firebaseConfig;