import React from 'react';
import Chat from '../Components/Chat';


const Chatroom = (props) => {

  return (
    <div>
 
        <Chat 
        // seProgresstProgress={props.setProgress} 
        />
    
    </div>
  )
}

export default Chatroom